<template>
    <page-title
        icon="bi-people-fill"
        title="ユーザ管理"
    >
        <button-exec
            icon="bi-arrow-repeat"
            text="Googleアカウント同期"
            class="me-3"
            @click="$refs.confirm_sync.show();"
        ></button-exec>
        <button-go-create
            :to="{name: 'UserAdd'}"
            icon="bi-person-plus"
        ></button-go-create>
    </page-title>

    <section class="section">
        <form @submit.prevent="search()" class="row align-items-end">
            <div class="form-group col-md-8">
                <label>氏名/メールアドレス/ログインID</label>
                <form-input
                    v-model="condition.keyword"
                ></form-input>
            </div>
            <div class="form-group col-md-5">
                <label>権限</label>
                <form-select
                    v-model="condition.role"
                    :options="roles"
                    empty_option="-- 全て --"
                ></form-select>
            </div>
            <div class="form-group col-md-5">
                <label>アカウントタイプ</label>
                <form-select
                    v-model="condition.account_type"
                    :options="account_types"
                    empty_option="-- 全て --"
                ></form-select>
            </div>
            <div class="form-group col-md-3">
                <label>ログイン可否</label>
                <form-select
                    v-model="condition.is_available"
                    :options="is_available"
                    empty_option="-- 全て --"
                ></form-select>
            </div>
            <div class="col-md-3">
                <button-search
                    type="submit"
                    :class="{'disabled': loading}"
                ></button-search>
            </div>
        </form>
    </section>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <search-result
                :total="paginator.total"
                target=""
            ></search-result>

            <template v-if="users.length">
                <table-normal class="table">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center">氏名</th>
                            <th class="text-center">メールアドレス / ログインID</th>
                            <th class="text-center">アカウントタイプ</th>
                            <th class="text-center">ログイン可否</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user.user_id" :class="{'bg-light': !user.is_available}">
                            <td class="align-middle text-center">{{ user.user_name }}</td>
                            <td class="align-middle text-center">{{ user.identifier_display }}</td>
                            <td class="align-middle text-center">{{ user.account_type_display }}</td>
                            <td class="align-middle text-center">{{ user.is_available_display }}</td>
                            <td class="align-middle text-center">
                                <button-go-update
                                    :to="{name: 'UserEdit', params: {id: user.user_id}}"
                                ></button-go-update>
                            </td>
                        </tr>
                    </tbody>
                </table-normal>
                <paginator :meta="paginator" @move="search"></paginator>
            </template>
            <alert-no-record v-else></alert-no-record>
        </template>
    </section>

    <confirm-dialog ref="confirm_sync" @ok="syncGoogle">
        <p>Googleアカウントの同期を開始してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import User from '@/models/entities/user';
import AccountType from '@/models/enums/account-type';
import IsAvailable from '@/models/enums/is-available';
import Role from '@/models/enums/role';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Paginator from '@/components/tools/Paginator';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonGoCreate from '@/components/buttons/ButtonGoCreate.vue';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate.vue';
import TableNormal from '@/components/tables/TableNormal.vue';
import SearchResult from '@/components/notice/SearchResult.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'UserList',
    components: {
        ConfirmDialog,
        InlineLoader,
        Paginator,
        FormInput,
        FormSelect,
        ButtonExec,
        ButtonSearch,
        ButtonGoCreate,
        ButtonGoUpdate,
        TableNormal,
        SearchResult,
        AlertNoRecord,
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: false,

            //検索オプション
            condition: {
                keyword: '',
                role: null,
                account_type: null,
                is_available: IsAvailable.YES,
                page: 1,
            },

            //選択肢
            account_types: AccountType.options(),
            is_available: IsAvailable.options(),
            roles: Role.options(),

            //表示データ
            users: [],

            //ページネーター
            paginator: {},
        }
    },
    mounted() {
        //storeに前回の検索条件があれば
        this.condition = this.$store.getters['condition/merge']('UserList', this.condition);
        this.search();
    },
    methods: {
        //検索
        search(page = 1) {
            this.condition.page = page;

            //表示データ初期化
            this.users.splice(0);

            this.loading = true;
            this.$http.get('/user', {
                params: this.condition
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.users.push(new User(row));
                }
                //ページネーション用
                this.paginator = response.data.meta;
                //検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'UserList',
                    condition: this.condition
                });
            })
            .finally(() => {
                this.loading = false;
            });
        },
        //Googleアカウント同期するバッヂを走らせる
        syncGoogle() {
            this.startScreenLoading();

            this.$http.post('/user/sync-google')
            .then(() => {
                this.showMessage('Googleアカウントの同期を開始しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>

</style>
